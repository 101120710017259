







































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { StepFourInterface } from '@/store/modules/room.module'

const MAX_PICTURE_SIZE = 1048576 * 5 // 5mb
const MAX_TOTAL_PICTURE_SIZE = 1048576 * 32 // 32 mb

@Component({
  name: 'StepFour',
  components: { BaseButton, BaseIcon, vueCustomScrollbar },
})
export default class StepFour extends Vue {
  @Prop({ required: true })
  value!: StepFourInterface

  @Watch('value')
  onValueChanged(val: StepFourInterface): void {
    this.updateModel(val)
  }

  images: File[] = []
  imagesPreviews: string[] = []
  limit = 15

  @Ref('inputFile') readonly file!: HTMLInputElement

  openWindow(): void {
    const element: HTMLElement = this.$refs.inputFile as HTMLElement
    if (element) {
      element.click()
    }
  }

  @Emit('change-images')
  change(event: Event): File[] {
    const element = event.target as HTMLInputElement
    const file: File = (element.files as FileList)[0]

    if (
      file &&
      this.images.length <= this.limit &&
      this.isLoadingFileValid(file) &&
      this.totalPicturesSize < MAX_TOTAL_PICTURE_SIZE
    ) {
      const url = URL.createObjectURL(file)

      this.images.push(Object.assign(file, {}))
      this.imagesPreviews.push(url)

      this.update()
    }

    return this.images
  }

  deleteImage(idx: number): void {
    this.images.splice(idx, 1)
    this.imagesPreviews.splice(idx, 1)
  }

  public isLoadingFileValid(file: File): boolean {
    return (
      (file.name.includes('jpg') || file.name.includes('png')) &&
      file.size < MAX_PICTURE_SIZE
    )
  }

  public checkValidity(): boolean {
    return (
      this.images &&
      this.images.length > 0 &&
      this.totalPicturesSize < MAX_TOTAL_PICTURE_SIZE
    )
  }

  public updateModel(data: StepFourInterface): void {
    this.images = data.photos as File[]
  }

  get totalPicturesSize(): number {
    return this.images?.reduce((acc, img) => acc + img.size, 0)
  }

  public update(): void {
    this.$emit('input', {
      photos: this.images,
    })
  }

  public mounted(): void {
    this.updateModel(this.value)
  }
}
